import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Theme, ThemeProvider, createTheme } from "@mui/material/styles";
import Login from "./pages/Login";
import LeaveApproval from "./pages/LeaveApproval";
import { Provider } from "react-redux";
import configurePersistedStore from "./store/configureStore";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import ApproverDashboard from "./pages/ApproverDashboard";
import WellKNowncomp from "./components/WellKnowncomp";
declare module "@mui/styles" {
  interface DefaultTheme extends Theme {}
}
export const client = new ApolloClient({
  uri: "https://leavetracker.api.anzilsoft.com/graphql",
  cache: new InMemoryCache(),
});
const initialState = ({} as any).initialReduxState;
const { store, persistor } = configurePersistedStore(initialState);
let theme = createTheme({
  palette: {
    primary: {
      main: "#155e75",
      contrastText: "#fff",
      dark: "#0F0F0F",
      light: "#F4C3C4",
    },
    secondary: {
      main: "#edf2ff",
      light: "#FAFAFA",
      dark: "#101010",
    },
  },
  typography: {
    fontFamily: '"Poppins"',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
const email = localStorage.getItem("name");
const password = localStorage.getItem("password");
const jwt = localStorage.getItem("jwt");
const router = createBrowserRouter([
  {
    path: "/" ,
    element: <Login />,
  },
  {
    path: "/.well-known/assetlinks.json" ,
    element: <WellKNowncomp/>,
  },
  {
    path: "/approve",
    element: email && password && jwt ? <LeaveApproval /> : <Login />,
  },
  {
    path: "/dashboard",
    element: email && password && jwt ? <ApproverDashboard /> : <Login />,
  }
]);
function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <RouterProvider  router={router} />
        </ApolloProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
