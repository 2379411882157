import React, { useState } from 'react'
import data from "../data/assetlinks.json"

function WellKnowncomp() {
    const [jsonData, setJsonData] = useState<any>(data);
    console.log(JSON.stringify(data));
  return (
    <div>{JSON.stringify(data)}</div>
  )
}

export default WellKnowncomp