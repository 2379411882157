import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from "@mui/material";
import AcceptRejectDialog from "./AcceptRejectDialog";
import { useState, useEffect } from "react";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
interface EmployeeCardProps {
  name?: string;
  employeeCode?: any;
  leaveType?: any;
  email?: any;
  phoneNumber?: any;
  reason?: any;
  noOfDays?: any;
  handleApprove?: any;
  handleReject?: any;
  id?: any;
  status?: any;
}
const EmployeeCard = (props: EmployeeCardProps) => {
  const [open, setOpen] = useState(false);
  const [rejectedReason, setRejectedReason] = useState("");
  const {
    name,
    employeeCode,
    leaveType,
    email,
    phoneNumber,
    reason,
    noOfDays,
    id,
    status,
  } = props;
  console.log(status);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e: any) => {
    setRejectedReason(e.target.value);
  };
  const rejectLeave = () => {
    props.handleReject(id, "Rejected", rejectedReason, email);
    handleClose();
  };

  return (
    <Box sx={{ mt: 10, pt: 2, pb: 2 }} boxShadow={3}>
      <Typography variant="h4" textAlign={"center"} fontWeight={"bold"}>
        Pending Leaves to Approve
      </Typography>
      <Card variant="outlined" style={{ margin: "16px" }}>
        <CardContent>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box>
              <Typography variant="h5" component="div">
                Name: {name}
              </Typography>
              <Typography>Employee Code: {employeeCode}</Typography>
              <Typography>Leave Type: {leaveType}</Typography>
              <Typography>Leave Reason: {reason}</Typography>
              <Typography>No of Days: {noOfDays}</Typography>
              <Typography>Email: {email}</Typography>
              <Typography>Phone: {phoneNumber}</Typography>
            </Box>
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              gap={2}
            >
              {status == "Pending" ? (
                <>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() =>
                      props.handleApprove(id, "Approved", "Accepted", email)
                    }
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClickOpen}
                  >
                    Reject
                  </Button>
                </>
              ) : status == "Approved" ? (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <CheckCircleOutlineIcon color="success" />
                    <Typography>Approved Successfully</Typography>
                  </Box>
                  <Box>
                    {" "}
                    <Button
                      onClick={() =>
                        props.handleApprove(id, "Pending", "Pending")
                      }
                    >
                      Revoke
                    </Button>
                  </Box>
                </Box>
              ) : status == "Rejected" ? (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <CancelIcon color="error" />
                    <Typography>Rejected</Typography>
                  </Box>
                  <Box>
                    <Button
                      onClick={() =>
                        props.handleApprove(id, "Pending", "Pending")
                      }
                    >
                      Revoke
                    </Button>
                  </Box>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reject</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please Enter Reason to Reject Applied Leave.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            id="outlined-basic"
            label="Reason"
            variant="outlined"
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={rejectLeave} color="error">
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EmployeeCard;
