// LoginPage.js
import React, { useState, useEffect } from "react";
import {
  Paper,
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  Box,
  Alert,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import banner from "../assets/images/leaveBanner.png";
import { authActionCreators } from "../store/auth";
import { connect, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../store/configureStore";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
type LoginProps = ReturnType<typeof mapStateToProps>;
const Login = (props: LoginProps) => {
  const [searchParams] = useSearchParams();
  const dispatch: AppDispatch = useDispatch();
  let navigate = useNavigate();
  const classes = useStyles();
  const [formData, setData] = useState({
    email: "",
    password: "",
    emailError: "",
    passError: "",
  });
  const validate = async () => {
    await dispatch(
      authActionCreators.userAuthLogin({
        identifier: formData.email.toLowerCase(),
        password: formData.password,
      })
    );
  };
  let id = Object.fromEntries([...searchParams]);
  useEffect(() => {
    if (
      props.user.isAuthenticated &&
      (props.user.isPortal == "approver" || props.user.isPortal == "admin")
    ) {
      if (Object.keys(id).length == 0) {
        navigate("/dashboard");
      } else if (Object.keys(id).length == 1) {
        navigate(`/approve?id=${id.id}`);
        window.location.reload()
      }
    } else {
    }
  }, [props.user.isAuthenticated, props.user.isPortal]);

  const handleUsernameChange = (e: any) => {
    setData({ ...formData, email: e.target.value });
  };

  const handlePasswordChange = (e: any) => {
    setData({ ...formData, password: e.target.value });
  };

  return (
    <Container className={classes.root} sx={{ display: "flex" }}>
      <Grid
        container
        spacing={6}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Grid item xs={8}>
          <Box
            sx={{
              backgroundImage: `url(${banner})`,
              height: "25rem",
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          ></Box>
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h5" component="h2" gutterBottom>
              Login
            </Typography>
            <form className={classes.form}>
              <TextField
                label="Username"
                variant="outlined"
                fullWidth
                required
                value={formData.email}
                onChange={handleUsernameChange}
                // error={errors.email? true: false}
              />
              <TextField
                label="Password"
                variant="outlined"
                type="password"
                fullWidth
                required
                value={formData.password}
                onChange={handlePasswordChange}
                // error={errors.password? true:false}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.submitButton}
                onClick={validate}
              >
                Log In
              </Button>
              {props.user.isUserLoginError ? (
                <Alert severity="error">{props.user.isUserLoginError}</Alert>
              ) : (
                <></>
              )}
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
function mapStateToProps(state: RootState) {
  return {
    user: state.auth as any,
  };
}

export default connect(mapStateToProps)(Login);
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },
  paper: {
    padding: theme.spacing(3),
    maxWidth: 400,
    width: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));
