import { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import Navbar from "../components/Navbar";
import { useSearchParams } from "react-router-dom";
import EmployeeCard from "../components/EmployeeCard";
import { RootState, AppDispatch } from "../store/configureStore";
import { connect, useDispatch } from "react-redux";
import auth, { authActionCreators, setEmployeeEmail } from "../store/auth";
import { useQuery, gql } from "@apollo/client";
const USER_EMAIL = gql`
  query userEmail($id: ID) {
    leaveApplication(id: $id) {
      data {
        attributes {
          employee_id {
            data {
              attributes {
                employee_user_ids {
                  data {
                    attributes {
                      email
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
type LeaveProps = ReturnType<typeof mapStateToProps>;
function LeaveApproval(props: LeaveProps) {
  const dispatch: AppDispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [id, setId] = useState<any | null>(null);
  let appId = Object.fromEntries([...searchParams]);
  const { data, loading, error } = useQuery(USER_EMAIL, {
    variables: { id: appId.id },
  });
  let empEmail =
    data?.leaveApplication.data.attributes.employee_id.data.attributes
      .employee_user_ids.data[0].attributes.email;
  // if (empEmail){
  //   dispatch(setEmployeeEmail(empEmail))
  // }
  useEffect(() => {
    setId(Object.fromEntries([...searchParams]));
    dispatch(
      authActionCreators.getLeaveInfo(Object.fromEntries([...searchParams]))
    );
    console.log(props.user.leaveStatus);
  }, []);
  const handleApprove = (id: any, status: any, reason: any,email:any) => {
    dispatch(authActionCreators.updateLeaveApplication(id, status, reason,email));
  };
  const handleReject = (id: any, status: any, reason: any,email:any) => {
    dispatch(authActionCreators.updateLeaveApplication(id, status, reason,email));
  };
  useEffect(() => {
    // console.log(props.user.leaveStatus);
  }, [props.user]);
  return (
    <>
      <Navbar />
      <Box>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item xs={12} xl={10} >
            <EmployeeCard
              name={props.user.empInfo.attributes?.employee_name}
              employeeCode={props.user.empInfo.attributes?.employee_code}
              leaveType={props.user.leaveInfo.leave_type}
              reason={props.user.leaveInfo.reason}
              email={empEmail}
              phoneNumber={props.user.empInfo?.attributes?.phone}
              noOfDays={props.user.leaveInfo.number_of_days}
              handleApprove={handleApprove}
              handleReject={handleReject}
              id={Object.fromEntries([...searchParams])}
              status={props.user.leaveStatus}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
function mapStateToProps(state: RootState) {
  return {
    user: state.auth as any,
  };
}

export default connect(mapStateToProps)(LeaveApproval);
