import { configureStore } from "@reduxjs/toolkit";
// ...
import { combineReducers } from "redux";

import storageSession from "redux-persist/lib/storage/session";
import { BrowserHistoryOptions, createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import authReducer from './auth'
// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName("base")[0]?.getAttribute("href");
export const history = createBrowserHistory() as any;

const reducers = {
  auth:authReducer,
};

const rootReducer = combineReducers({
  router: connectRouter(history),
  ...reducers,
});

const persistConfig = {
  key: "root",
  storage: storageSession,
  blacklist: ["router"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof persistedReducer>;
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(routerMiddleware(history)),
  devTools: process.env.NODE_ENV !== "production",
});
const persistor = persistStore(store);
export default function configurePersistedStore(initialState: RootState) {
  return { store, persistor };
}
// export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}

export type AppDispatch = typeof store.dispatch
