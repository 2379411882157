import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store/configureStore";
import { userLogout } from "../store/auth";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const dispatch: AppDispatch = useDispatch();
  let navigate = useNavigate();
  const handleSignOut = () => {
    // Implement your signout logic here
    dispatch(userLogout());
    navigate("/");
    localStorage.clear();
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Leave App
        </Typography>
        <Button color="inherit" onClick={handleSignOut}>
          Sign Out
          <ExitToAppIcon sx={{ ml: 1 }} />
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
