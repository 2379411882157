import { leaveInfo, userData } from "../definition";
import { createSlice } from '@reduxjs/toolkit';
import type { AnyAction, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './configureStore';
import axios from "axios";
import { gql, useQuery } from "@apollo/client";
import { client } from "../App";

export interface authState {
  isLoggedIn: boolean;
  isUserLoginError: string;
  isLoginSuccess: boolean;
  isAuthenticated: boolean;
  userData: userData;
  isPortal: string;
  leaveInfo: leaveInfo;
  empInfo: any;
  empEmail: any;
  leaveStatus: any;
  approverData: any;
}
const initialState: authState = {
  isLoggedIn: false,
  isUserLoginError: '' as any,
  isLoginSuccess: false,
  isAuthenticated: false,
  isPortal: '' as any,
  userData: {} as userData,
  leaveInfo: {} as leaveInfo,
  empInfo: {} as any,
  empEmail: '',
  leaveStatus: '' as any,
  approverData: [] as any
}
export const auth: any = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userLogin: (state: authState, action: PayloadAction<any>) => {
      return {
        ...state,
        isUserLoginLoading: false,
        isUserLoginError: '',
        isLoginSuccess: true,
        userData: action.payload,
      };
    },
    setUserLoading: (state: authState) => {
      return {
        ...state,
        isUserLoginLoading: true,
        isUserLoginError: '',
      };
    },
    setUserError: (state: authState, action: PayloadAction<string>) => {
      return {
        ...state,
        isUserLoginLoading: false,
        isUserLoginError: action.payload,
      };
    },
    setPortal: (state: authState, action: PayloadAction<any>) => {
      return {
        ...state,
        isPortal: action.payload,
      };
    },
    setAuthentication: (state: authState, action: PayloadAction<any>) => {
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    },
    setJWT: (state: authState, action: PayloadAction<any>) => {
      return {
        ...state,
        jwtToken: action.payload,
      };
    },
    userLogout: () => initialState,
    setLeaveData: (state: authState, action: PayloadAction<any>) => {
      return {
        ...state,
        leaveInfo: action.payload
      }
    },
    setEmployeeDetails: (state: authState, action: PayloadAction<any>) => {
      return {
        ...state,
        empInfo: action.payload
      }
    },
    setEmployeeEmail: (state: authState, action: PayloadAction<any>) => {
      return {
        ...state,
        empEmail: action.payload
      }
    },
    setLeaveStatus: (state: authState, action: PayloadAction<any>) => {
      return {
        ...state,
        leaveStatus: action.payload
      }
    },
    setApproverData: (state: authState, action: PayloadAction<any>) => {
      return {
        ...state,
        approverData: action.payload
      }
    }
  }
});
export const {
  userLogin,
  setUserLoading,
  setUserError,
  setPortal,
  setAuthentication,
  setJWT,
  userLogout,
  setLeaveData,
  setEmployeeDetails,
  setEmployeeEmail,
  setLeaveStatus,
  setApproverData
} = auth.actions;
export default auth.reducer;
const APPROVER_DATA = gql`
query approverData($id:ID){
  usersPermissionsUser(id:$id){
    data{
      attributes{
        user_approver_ids{
          data{
            attributes{
              employee_name
              leave_applications{
                data{
                  attributes{
                    reason
                    leave_type
                    leave_status
                    start_date
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
const userAuthLogin = (userData: { identifier: any; password: any }) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(setUserLoading());
      const resp = await axios.post(
        `https://leavetracker.api.anzilsoft.com/api/auth/local`,
        userData,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      if (resp.data) {
        localStorage.setItem('name', userData.identifier);
        localStorage.setItem('password', userData.password);
        localStorage.setItem('jwt', resp.data.jwt);
        dispatch(userLogin(resp.data));
        dispatch(setJWT(resp.data.jwt));
        dispatch(setAuthentication(true));
        dispatch(setPortal(resp.data.user.user_role.toLowerCase()));
        try {
          const data = await client.query({
            query: APPROVER_DATA,
            variables: { id: resp.data.user.id }
            // variables:{id:1120}
          })
          dispatch(setApproverData(data.data.usersPermissionsUser.data.attributes.user_approver_ids.data))
        } catch (error) {
          console.log(error)
        }
      }
    } catch (error: any) {
      console.log(error.response.data.error.message)
      dispatch(setUserError(error.response.data.error.message));
      setTimeout(() => {
        dispatch(setUserError(''));
      }, 2000);
    }
  }

const getLeaveInfo = (id: any) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      const resp = await axios.get(
        `https://leavetracker.api.anzilsoft.com/api/leave-applications/${id.id}?populate=employee_id`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      dispatch(setLeaveStatus(resp.data.data.attributes.leave_status))
      if (resp.data) {
        try {
          const resp = await axios.get(
            `https://leavetracker.api.anzilsoft.com/api/leave-applications/${id.id}?populate=employee_id`,
            {
              headers: {
                'content-type': 'application/json',
              },
            },
          );
        } catch (error) {

        }
        dispatch(setLeaveData(resp.data.data.attributes));
        dispatch(setEmployeeDetails(resp.data.data.attributes.employee_id.data
        ))
      }
    } catch (error: any) {
      console.log(error)
    }
  }
const updateLeaveApplication = (id: any, status: any, reason: any, email: any) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      const resp = await axios.put(
        `https://leavetracker.api.anzilsoft.com/api/leave-applications/${id.id}`,
        {
          data: {
            leave_status: status,
            rejected_reason: reason
          }
        },
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      if (resp.data.data.attributes.leave_status == "Approved") {
        try {
          const response = await axios.post(
            `https://leavetracker.api.anzilsoft.com/api/email`,
            {
              to: `${email}`,
              from: 'no-reply@anzilsoft.com',
              subject: ` Your Leave Have Been Approved`,
              text: `<body style="font-family: Arial, sans-serif; background-color: #f0f0f0; text-align: center;">
        <table width="100%" cellspacing="0" cellpadding="0">
            <tr>
                <td style="padding: 20px;">
                    <table align="center" width="600" cellspacing="0" cellpadding="0" style="background-color: #ffffff; border-radius: 10px; box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);">
                        <tr>
                            <td style="padding: 40px;">
                                <h1 style="font-size: 24px; color: #333;"> had Applied for Leave</h1>
                                <p style="font-size: 16px; color: #666; margin-bottom: 20px;">Approved</p>
                                <table width="100%" cellspacing="0" cellpadding="0">
                                    <tr>
                                        
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </body>`,
            },
            {
              headers: {
                'content-type': 'application/json',
              },
            },
          );

        } catch (error) {
          console.log(error)
        }
      } else if (resp.data.data.attributes.leave_status == "Rejected") {
        try {
          const response = await axios.post(
            `https://leavetracker.api.anzilsoft.com/api/email`,
            {
              to: `${email}`,
              from: 'no-reply@anzilsoft.com',
              subject: ` Your Leave Have Been Rejected`,
              text: `<body style="font-family: Arial, sans-serif; background-color: #f0f0f0; text-align: center;">
        <table width="100%" cellspacing="0" cellpadding="0">
            <tr>
                <td style="padding: 20px;">
                    <table align="center" width="600" cellspacing="0" cellpadding="0" style="background-color: #ffffff; border-radius: 10px; box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);">
                        <tr>
                            <td style="padding: 40px;">
                                <h1 style="font-size: 24px; color: #333;"> had Applied for Leave</h1>
                                <p style="font-size: 16px; color: #666; margin-bottom: 20px;">Rejected</p>
                                <table width="100%" cellspacing="0" cellpadding="0">
                                    <tr>
                                        
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </body>`,
            },
            {
              headers: {
                'content-type': 'application/json',
              },
            },
          );

        } catch (error) {
          console.log(error)
        }
      }
      dispatch(setLeaveStatus(resp.data.data.attributes.leave_status))
    } catch (error) {
      console.log(error)
    }
  }

export const authActionCreators = {
  userAuthLogin,
  getLeaveInfo,
  updateLeaveApplication
}

