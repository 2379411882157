import { useState } from "react";
import Navbar from "../components/Navbar";
import { Grid, Box, Typography, Paper } from "@mui/material";
import { connect } from "react-redux";
import { RootState } from "../store/configureStore";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
type ApproverDataProps = ReturnType<typeof mapStateToProps>;
function ApproverDashboard(props: ApproverDataProps) {
  console.log(props.user.approverData);
  const [open, setOpen] = useState(false);
  const [leaves, setLeaves] = useState([]);
  const [fullWidth, setFullWidth] = useState(true);

  const handleClickOpen = (e: any) => {
    console.log(e.leaves, "Any");
    setLeaves(e.leaves);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const approverName = props?.user?.approverData?.map((e: any) => {
    return {
      name: e.attributes.employee_name,
      numOfLeave: e.attributes.leave_applications.data.length,
      leaves: e.attributes.leave_applications.data,
    };
  });

  return (
    <div>
      <Navbar />
      <Box>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item xs={12} xl={10}>
            <Box sx={{ mt: 10, p: 2 }} boxShadow={3}>
              <Typography variant="h4" textAlign={"center"} fontWeight={"bold"}>
                Approver Dashboard
              </Typography>
              <Box>
                <Typography variant="h6">Employees</Typography>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {approverName.map((e: any) => {
                      return (
                        <Grid
                          item
                          xs={3}
                          xl={3}
                          onClick={() => handleClickOpen(e)}
                          sx={{ cursor: "pointer" }}
                        >
                          <Paper sx={{ p: 1, bgcolor: "primary.main" }}>
                            <Typography
                              color="primary.contrastText"
                              fontWeight={"bold"}
                              sx={{ display: "inline-flex" }}
                            >
                              Name:<Typography>{e.name}</Typography>
                            </Typography>
                            <Typography
                              color="primary.contrastText"
                              sx={{ display: "inline-flex" }}
                              fontWeight={"bold"}
                            >
                              Number Of Applications: {e.numOfLeave}
                            </Typography>
                          </Paper>
                        </Grid>
                      );
                    })}
                    {
                      <Dialog fullWidth open={open} onClose={handleClose}>
                        <DialogTitle>Leaves</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Total Leaves Applied By Employee
                          </DialogContentText>
                          <Box
                            noValidate
                            component="form"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              m: "auto",
                              width: "fit-content",
                            }}
                          >
                            {leaves.map((e: any) => {
                              return (
                                <Box display="flex">
                                  <Typography sx={{mr:2}}>
                                    Reason: {e.attributes.reason}
                                  </Typography>
                                  <Typography>
                                    Leave Status: {e.attributes.leave_status}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Box>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                      </Dialog>
                    }
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
function mapStateToProps(state: RootState) {
  return {
    user: state.auth as any,
  };
}

export default connect(mapStateToProps)(ApproverDashboard);
